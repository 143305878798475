import React from "react"
import { Row, Col } from "react-flexbox-grid"
import HelmetWrapper from "../../components/HelmetWrapper"
import "./index.scss"
import Hero from "../../components/Hero"
import ContentBlock from "../../components/ContentBlock"
import headerHero from "../../assets/images/header_office.jpg"
import Layout from "../../components/Layout"
import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor"

const pageTitle = "Privacy Policy & Terms of Use"
const pageDescription = "Privacy Policy and Terms of Use - Harmony Biosciences"
const pageKeywords = ""
configureAnchors({ offset: -148, scrollDuration: 200 })

const privacy = () => (
  <div id="overview">
    <Layout jobCode="US-HAR-2200062/Aug 2022">
      <HelmetWrapper
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageKeywords={pageKeywords}
        slug="/privacy-policy-terms-of-use"
      />
      <Hero bg={headerHero}>
        <h1>Privacy Policy</h1>
      </Hero>
      <ContentBlock color="white">
        <Row between="xs">
          <Col xs={12}>
            <h2>PRIVACY POLICY</h2>
            <p>
              <strong>Last updated April 2020</strong>
            </p>
            <p>
              <strong>Harmony Biosciences (“Harmony”)</strong> has provided this
              website and its content for general informational purposes only,
              for US residents. Harmony and its affiliates value your privacy
              and the security of your personal information. This notice
              explains our online information practices. This Privacy Policy
              applies only to the use and collection of data collected by this
              website, its services, and related mobile applications, and does
              not apply to any other data collected by Harmony online or off
              line unless specifically linked to this Privacy Policy.
            </p>
            <p>
              Harmony operates our websites and any mobile applications that are
              owned by us. As used in this Privacy Policy, "We" includes Harmony
              and its related companies, as well as any third-party vendors we
              hire to assist in administration of the website. These vendors are
              required to maintain the confidentiality of user information in
              accordance with the terms of this Privacy Policy and to otherwise
              abide by the terms of this Privacy Policy. These vendors are
              prohibited from using the user information for any other purpose.
              We encourage you to read the Privacy Policy in its entirety before
              using the site. By accessing and using the site or its services,
              you agree to the collection and use of information in accordance
              with this Privacy Policy, as well as to the Terms of Use.
            </p>
            <h2>Personally Identifiable Information </h2>
            <p>
              Harmony collects personally identifiable information (i.e.
              information from which you can be identified, such as name,
              address, telephone number or email address) only when you
              voluntarily submit it to us through this website. We may use the
              information to respond to your requests, improve our level of
              service and for our own internal purposes.{" "}
            </p>
            <h2>Non-personally Identifiable Information </h2>
            <p>
              Harmony also collects non-personally identifiable information in
              aggregate form to track data such as total number of visits to our
              website, the number of visits to each page of our website, and the
              domain names of our internet service providers. We use this
              information, which remains in aggregate, non-personally
              identifiable form, to understand how our visitors use our website
              so that we may improve our website and the services we offer.{" "}
            </p>
            <h2>Cookies</h2>
            <p>
              Cookies are small data files that may include an anonymous unique
              identifier. Cookies are sent to your browser from this website and
              stored on your device’s hard drive to collect information. These
              data allow us to store your preferences and settings, protect you
              from fraud, and analyze the performance of our services. You have
              the right not to provide us with any personal data when you visit
              the site and may refuse all cookies. However, if you do not accept
              cookies, you may not be able to use some portions of the site.
              Cookies stored on your hard drive can be deleted by you at any
              time through your browser settings. For more detail and
              information on how to manage cookies, see{" "}
              <a href="http://www.allaboutcookies.org">
                www.allaboutcookies.org
              </a>
              .
            </p>
            <h2>Security</h2>
            <p>
              Your personal data are important to us. Harmony Biosciences takes
              several steps to ensure the security of your personal data, but
              transmission of information over the internet and through
              electronic storage is not 100% secure. While we use commercially
              acceptable methods to protect your personal data, we cannot
              guarantee their absolute security.
            </p>
            <h2>Sharing of Information </h2>
            <p>
              Other Parties When Required by Law or as Necessary to Protect the
              Services
              <br />
              While the information you provide will be retained for a
              commercially reasonable time by Harmony for administrative,
              liability, and archival purposes, your personally identifying
              information is not shared with a third party, other than{" "}
              <strong>(1)</strong> as required by law, <strong>(2)</strong> to
              protect and defend the rights of Harmony, the website, or the
              users of the website, <strong>(3)</strong> as incident to a
              corporate sale, merger, reorganization, dissolution, bankruptcy,
              or similar event, <strong>(4)</strong> under circumstances we
              believe reasonably necessary to protect the personal safety of
              users of the site or its services, or the public, or{" "}
              <strong>(5)</strong> as is otherwise described in this Privacy
              Policy. We may share your information with third-party vendors
              that perform certain functions or services on our behalf (such as
              to host the site, manage databases, perform analyses, or send
              communications). These vendors may only use the information for
              the purpose for which it was disclosed to them and they are
              required to maintain the confidentiality of user information in
              accordance with the terms of this Privacy Policy and to otherwise
              abide by the terms of this Privacy Policy. These vendors are
              prohibited from using the user information for any other purpose
              or in any other manner.
            </p>
            <h2>Third-Party Services</h2>
            <p>
              Harmony Biosciences is not responsible for the privacy policies or
              practices of any third parties, including any third party
              operating a website or service to which our site links or that
              provides a link to our site. The inclusion of a link on our site
              to an external source does not imply our endorsement of the linked
              website or service. You should review the privacy policies of
              third-party websites so you can understand how they collect, use,
              and share your information.
            </p>
            <h2>Social Networks</h2>
            <p>
              You may choose to access or interact with social media websites
              owned by certain third parties (such as Facebook) through our
              site. When you choose to link to these websites, you are sharing
              information with these websites, you are subject to their policies
              regarding cookies and tracking, and the information you share will
              be governed by their privacy policies. Social media websites may
              also share information with us and may post information about your
              visit to our site on their pages. You may be able to modify your
              privacy settings with these third-party websites.
            </p>
            <h2>California Privacy Rights</h2>
            <p>
              State law permits California residents to annually request and
              obtain information free of charge about what personal information
              is disclosed by Harmony to third parties for third-party direct
              marketing purposes in the preceding calendar year. Harmony does
              not share information that it collects with third parties for the
              third party’s direct marketing purposes.
            </p>
            <h2>Website Use by Children</h2>
            <p>
              This site and its services are not directed toward children under
              13 years of age, nor does Harmony Biosciences knowingly collect,
              use, or disclose personal information from children under the age
              of 13 without prior parental consent, except as permitted by the
              Children’s Online Privacy Protection Act (“COPPA”). If you are
              under the age of 13, please do not use the site or submit any
              personal information to us. If you believe that we have
              unintentionally collected personal information about your child,
              you can contact us using the information provided below.
            </p>
            <h2>Contacting Harmony About Your Personal Information </h2>
            <p>
              To gain access to your personally identifiable information
              collected online, and to keep it accurate, complete and current,
              or to request deletion, you may contact Harmony at{" "}
              <a href="mailto:privacy@harmonybiosciences.com">
                privacy@harmonybiosciences.com
              </a>
              . In some cases, where we are required to retain information by
              law or regulation, or to continue to manage a request that you
              have made, or to ensure that we honor your preferences, or for
              other necessary business purposes, we may not be able to delete
              certain personal information about you.{" "}
            </p>
            <p>
              Should you have questions about this policy or Harmony’s
              information collection, use and disclosure practices, you may
              contact us via email at{" "}
              <a href="mailto:privacy@harmonybiosciences.com">
                privacy@harmonybiosciences.com
              </a>
              .{" "}
            </p>
            <h2>Changes to This Privacy Policy</h2>
            <p>
              This Privacy Policy is effective as of the last date mentioned at
              the top of the policy and will remain in effect except with regard
              to any changes, which will be in effect immediately after being
              posted on this page. Because privacy as it applies to the internet
              can rapidly change, we may make amendments to this Privacy Policy
              that affect the use of your personal data. We reserve the right to
              change the terms of this Privacy Policy at any time by posting
              revisions to the site. You should periodically review this Privacy
              Policy for changes. If you do not agree to the terms of this
              Privacy Policy or revisions to this Privacy Policy, please exit
              the site immediately.
            </p>
            <ScrollableAnchor id={"terms"}>
              <h2>TERMS OF USE</h2>
            </ScrollableAnchor>
            <p>
              <strong>Last updated April 2020</strong>
            </p>
            <p>
              Harmony Biosciences (“Harmony”) has provided this website and its
              content for general informational purposes only, for US residents.
              Harmony will undertake reasonable efforts to ensure that all
              information added to this site is accurate at the time such
              content is made available. Please note, however, that Harmony
              makes no warranties or representations that the information is
              accurate and accepts no liability for any errors or omissions in
              the content of the site or any losses or damage that may arise
              from relying on this information. The information may be changed
              by Harmony at any time.
            </p>
            <p>
              We encourage you to read these Terms of Use in their entirety
              before using the site. By accessing and using the site or its
              services, you agree to these Terms of Use and to the collection
              and use of information in accordance with our Privacy Policy,
              without limitation or qualification.
            </p>
            <h2>Use of Materials and Intellectual Property Rights</h2>
            <p>
              This website and its services contain content specifically
              provided by Harmony or its partners. Such content is protected by
              copyrights, trademarks, and/or other proprietary rights and laws.
              Users of this site shall abide by all copyright notices,
              information, and restrictions contained in any such content. The
              user shall not sell, license, rent, modify, distribute, copy,
              reproduce, transmit, publicly display, publicly perform, publish,
              adapt, edit, create derivative works from, or otherwise exploit
              any content or third-party submissions or other proprietary rights
              not owned by the user, (i) without the consent of the respective
              owners or other valid right, and (ii) in any way that violates any
              third-party right.
            </p>
            <p>
              Harmony authorizes you to copy materials published on this website
              for noncommercial use only, provided that any copy of these
              materials which you make retains all copyright and other
              proprietary notices.
            </p>
            <h2>Links to and from Third-Party Websites</h2>
            <p>
              This website may from time to time contain in-text links to
              third-party websites, and third-party websites may provide links
              to this site. Harmony is not responsible for, and accepts no
              liability in respect to, any information or opinion contained on
              any third-party site. As Harmony has no control over such sites
              and because their content is subject to change without notice to
              Harmony, it is the responsibility of the user of the site to
              ensure that any information accessed is accurate and that software
              which is downloaded from that site is free of viruses or any other
              items of a destructive nature. Harmony accepts no liability
              whatsoever in respect to any loss, damage, costs or liability
              incurred through any such downloaded material.
            </p>
            <h2>Site Access</h2>
            <p>
              Harmony makes no representation or warranty that access to this
              website will be available on a timely basis, will be
              uninterrupted, or will be error-free. Harmony does not warrant
              that these pages, or the server that makes them available, are
              free of viruses or other harmful elements. In no event shall
              Harmony, its affiliates and subsidiaries, and their respective
              officers, directors, employees, agents, representatives,
              information providers, and licensors and their respective heirs
              and assigns, be liable for any direct, indirect, incidental,
              consequential, exemplary, special, punitive, or other damages,
              even if informed of the possibility of such damages. The above
              exclusion may not apply in jurisdictions to the extent that they
              do not allow the exclusion of implied warranties. Any personally
              identifiable information in electronic communications to this
              website is governed by Harmony’s Privacy Policy. Harmony shall be
              free to use or copy all other information in any such
              communications, including any ideas, inventions, concepts,
              techniques or know-how disclosed therein, for any purposes,
              including disclosure to third parties and/or developing,
              manufacturing and/or marketing products or services.
            </p>
            <h2>Changes to Terms of Use</h2>
            <p>
              Harmony may modify these Terms of Use at any time without notice.
              Harmony may terminate these Terms of Use, terminate user access to
              all or any part of this site or any content or any service, or
              suspend any individual user’s access to all or any part of this
              site or any content or any service, at any time, without notice,
              if we believe in our sole judgment that the user has breached or
              may breach any term or condition of this agreement, or for our
              convenience. The user may terminate these Terms of Use at any time
              by destroying all materials received from this site (including
              content) and ceasing to use this site and its content and
              services. Harmony reserves the right to remove any content or
              services from the site at any time.
            </p>
            <h2>Agreement to Terms of Use</h2>
            <p>
              By accessing and using this website or its services, you agree to
              these Terms of Use and to the collection and use of information in
              accordance with our Privacy Policy, without limitation or
              qualification. Except as expressly provided in a particular “legal
              notice” on this website, these Terms of Use constitute the entire
              agreement between the user and Harmony with respect to the use of
              this site and its content and services. All materials and
              information appearing on this website are intended for US
              residents only.
            </p>
          </Col>
        </Row>
      </ContentBlock>
    </Layout>
  </div>
)

export default privacy
